var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container Notice" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "88px" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "50px" }, attrs: { gutter: 24 } },
                  [
                    _c("Head", { attrs: { name: _vm.title } }),
                    _c("div", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: [""],
                          expression: "['']",
                        },
                      ],
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 13 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建"],
                                expression: "['新建']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                ;(_vm.dialogStatus = "create"),
                                  (_vm.dialogFormVisible = true),
                                  _vm.resetTemps()
                              },
                            },
                          },
                          [_vm._v("新建")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["编辑"],
                                expression: "['编辑']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                ;(_vm.dialogStatus = "update"),
                                  (_vm.dialogFormVisible = true),
                                  _vm.infodata()
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["删除"],
                                expression: "['删除']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: { click: _vm.deleteBtn },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        border: "",
                        data: _vm.tableData,
                        "tooltip-effect": "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                        },
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", align: "center" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "序号", align: "center", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3093164208
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "标题",
                          align: "center",
                          "min-width": "50%",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "hide" }, [
                                    _c("p", [_vm._v(_vm._s(scope.row.title))]),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2766820176
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "内容",
                          align: "center",
                          "min-width": "50%",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", {
                                    staticClass: "hide",
                                    domProps: {
                                      innerHTML: _vm._s(scope.row.content),
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2394329309
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "create_time",
                          label: "添加时间",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "置顶", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.is_top == 1
                                    ? _c("span", [_vm._v("是")])
                                    : _c("span", [_vm._v("否")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3265532430
                        ),
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "60%",
                  top: "5vh",
                  title: _vm.textMap[_vm.dialogStatus],
                  visible: _vm.dialogFormVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  { attrs: { model: _vm.form } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "标题",
                          prop: "name",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { autocomplete: "off" },
                          model: {
                            value: _vm.form.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "title", $$v)
                            },
                            expression: "form.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "公告内容",
                          prop: "content",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _c("quill-editor", {
                          ref: "editor",
                          attrs: { options: _vm.editorOption },
                          model: {
                            value: _vm.form.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "content", $$v)
                            },
                            expression: "form.content",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "", "label-width": _vm.formLabelWidth },
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "active-text": "置顶",
                            "inactive-color": "#ff4949",
                          },
                          model: {
                            value: _vm.form.is_top,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_top", $$v)
                            },
                            expression: "form.is_top",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "选择此项后，此公告将在列表最前端展示(通常重要的通知可以选择置顶)"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible = false
                          },
                        },
                      },
                      [_vm._v("返 回")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogStatus === "create"
                              ? _vm.Announcement()
                              : _vm.EditorialAnnouncement()
                          },
                        },
                      },
                      [_vm._v("发 布")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }