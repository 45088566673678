import request from '@/utils/request';
export function Innouncement(data) {
  //zzy系统公告列表
  return request({
    url: '/clientserver.php/Announcement/index',
    method: 'post',
    data: data
  });
}
export function InnouncementSend(data) {
  //zzy新建系统公告
  return request({
    url: '/clientserver.php/Announcement/send',
    method: 'post',
    data: data
  });
}
export function InnouncementUpdate(data) {
  //zzy编辑系统公告
  return request({
    url: '/clientserver.php/Announcement/update',
    method: 'post',
    data: data
  });
}
export function InnouncementDelete(data) {
  //zzy编辑系统公告
  return request({
    url: '/clientserver.php/Announcement/delete',
    method: 'post',
    data: data
  });
}
// 系统配置
export function CustomerLevel(data) {
  //zzy系统配置列表
  return request({
    url: '/clientserver.php/CustomerLevel/list',
    method: 'post',
    data: data
  });
}
export function CustomerLevelCreate(data) {
  //zzy系统配置新增
  return request({
    url: '/clientserver.php/CustomerLevel/create',
    method: 'post',
    data: data
  });
}
export function CustomerLevelUpdate(data) {
  //zzy系统配置编辑
  return request({
    url: '/clientserver.php/CustomerLevel/update',
    method: 'post',
    data: data
  });
}
export function CustomerLevelDelete(data) {
  //zzy系统配置删除
  return request({
    url: '/clientserver.php/CustomerLevel/delete',
    method: 'post',
    data: data
  });
}
// 私海数据配置
export function PrivateSeaDeploy() {
  //yay私海数据配置
  return request({
    url: '/clientserver.php/PrivateSeaDeploy/index',
    method: 'get'
  });
}
export function PrivateSeaSetnum(data) {
  return request({
    url: '/clientserver.php/PrivateSeaDeploy/set_num',
    method: 'post',
    data: data
  });
}
//展业城市列表
export function PromoteCityList(data) {
  return request({
    url: '/clientserver.php/PromoteCity/index',
    method: 'post',
    data: data
  });
}
//展业城市新增
export function PromoteCityAdd(data) {
  return request({
    url: '/clientserver.php/PromoteCity/add',
    method: 'post',
    data: data
  });
}
//展业城市读取
export function PromoteCityRead(data) {
  return request({
    url: '/clientserver.php/PromoteCity/read',
    method: 'post',
    data: data
  });
}
//展业城市编辑
export function PromoteCityUpdate(data) {
  return request({
    url: '/clientserver.php/PromoteCity/update',
    method: 'post',
    data: data
  });
}
//展业城市修改状态
export function PromoteCityStatus(data) {
  return request({
    url: '/clientserver.php/PromoteCity/changeStatus',
    method: 'post',
    data: data
  });
}
//展业城市获取所有省
export function getAllProvince(data) {
  return request({
    url: '/clientserver.php/Common/get_all_province',
    method: 'post',
    data: data
  });
}
//展业城市获取一级部门
export function getOptionalStructure(data) {
  return request({
    url: '/clientserver.php/PromoteCity/getOptionalStructure',
    method: 'post',
    data: data
  });
}
//定级人员配置列表
export function getRankUserList(data) {
  return request({
    url: '/clientserver.php/AutoAssignRankUser/getRankUserList',
    method: 'post',
    data: data
  });
}
//参与推广部门
export function getPromotionStructure(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/getPromotionStructure',
    method: 'post',
    data: data
  });
}
//定级人员配置获取部门信息
export function structureData(data) {
  return request({
    url: '/clientserver.php/AutoAssignRankUser/structureData',
    method: 'post',
    data: data
  });
}
//新增或修改定级人员配置
export function addRankUser(data) {
  return request({
    url: '/clientserver.php/AutoAssignRankUser/addRankUser',
    method: 'post',
    data: data
  });
}
//新增或修改定级人员配置
export function getRankUserDetail(data) {
  return request({
    url: '/clientserver.php/AutoAssignRankUser/getRankUserDetail',
    method: 'post',
    data: data
  });
}
//删除定级人员配置
export function delRankUser(data) {
  return request({
    url: '/clientserver.php/AutoAssignRankUser/delRankUser',
    method: 'post',
    data: data
  });
}