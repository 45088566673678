//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Innouncement, InnouncementSend, InnouncementUpdate, InnouncementDelete } from '@/api/updateUserInfo/notice';
import Head from '@/components/head/index';
export default {
  name: 'Notice',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      title: '系统公告',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      currentPage4: 1,
      dialogFormVisible: false,
      tableData: [],
      textMap: {
        update: '编辑公告',
        create: '发布公告'
      },
      dialogStatus: '',
      formLabelWidth: '120px',
      form: {
        title: '',
        content: '',
        //内容（必填）
        is_top: true
      },
      details: {
        //单条数据详情
        id: '',
        title: '',
        // EffectiveDate: '',//有效日期
        content: '',
        //内容（必填）
        is_top: true
      },
      tempId: '',
      value1: ''
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
    } else {}
  },
  components: {
    Head: Head
  },
  methods: {
    getList: function getList(page, pagesize) {
      var _this = this;
      //公告列表
      Innouncement({
        page: page,
        pagesize: pagesize
      }).then(function (respomse) {
        _this.tableData = respomse.data.data;
        _this.query.total = respomse.data.total;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    resetTemps: function resetTemps() {
      //清空公告弹窗
      this.form = {
        title: '',
        // EffectiveDate: '',//有效日期
        content: '',
        //内容（必填）
        is_top: true
      };
    },
    Announcement: function Announcement() {
      var _this2 = this;
      //新增公告
      var data = {
        title: this.form.title,
        content: this.form.content,
        is_top: this.form.is_top == false ? 0 : 1
      };
      InnouncementSend(data).then(function (respomse) {
        _this2.dialogFormVisible = false;
        _this2.$notify({
          title: '成功',
          message: '发布成功',
          type: 'success',
          duration: 2000
        });
        _this2.resetTemps();
        _this2.getList();
      });
    },
    infodata: function infodata() {
      //获取公告详情
      console.log(this.details.content);
      if (this.tempId != '') {
        this.form = {
          title: this.details.title,
          content: this.details.content,
          //内容（必填）
          is_top: this.details.is_top
        };
      } else {
        this.dialogFormVisible = false;
        this.$message({
          type: 'info',
          message: '请选择公告'
        });
      }
    },
    EditorialAnnouncement: function EditorialAnnouncement() {
      var _this3 = this;
      //编辑公告
      if (this.dialogStatus == 'update') {
        var data = {
          id: this.tempId,
          title: this.form.title,
          content: this.form.content,
          is_top: this.form.is_top == false ? 0 : 1
        };
        InnouncementUpdate(data).then(function (respomse) {
          _this3.dialogFormVisible = false;
          _this3.$notify({
            title: '成功',
            message: '发布成功',
            type: 'success',
            duration: 2000
          });
          _this3.resetTemps();
          _this3.getList();
        });
      }
    },
    deleteBtn: function deleteBtn() {
      var _this4 = this;
      //删除公告
      if (this.tempId != '') {
        this.$confirm('此操作将永久删除该公告, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var data = {
            id: _this4.tempId
          };
          InnouncementDelete(data).then(function (res) {
            _this4.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
            _this4.getList();
          });
        }).catch(function () {
          _this4.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message({
          type: 'info',
          message: '请选择公告'
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //获取选中id
      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
        this.details = {
          id: this.tempId,
          title: val[i].title,
          // EffectiveDate: '',//有效日期
          content: val[i].content,
          //内容（必填）
          is_top: val[i].is_top == 1 ? true : false
        };
      }
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      }
    }
  }
};